<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item>工伤认定申报</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">工伤认定申报</div>
      </div>
      <el-button type="primary" @click="add" >新增</el-button>
      <div class="table-box">
        <el-table v-loading="queryTable.loading" :data="queryTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="applicant" label="申请人"></el-table-column>
          <el-table-column prop="injuredEmployees" label="受伤害职工"></el-table-column>
          <el-table-column prop="applicantInjuredRelation" label="申请人与受伤职工关系"></el-table-column>
          <el-table-column prop="contactNumber" label="联系电话"></el-table-column>
          <el-table-column prop="completionDate" label="填表日期"></el-table-column>
          <el-table-column prop="status " label="申报状态" :formatter="formatterStatus"></el-table-column>
          <el-table-column label="操作" width="90">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" v-if="scope.row.declareStatus != 3" style="color: #135694" type="text">编辑</el-button>
              <el-button @click="del(scope.row.id)" style="color: #135694" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pageSize"
            :total="queryTable.count"
            :current-page.sync="queryTable.page"
            @current-change="queryTable.search()"
          ></el-pagination>
        </el-row>
      </div>
    </div>
    <!-- <div class="content" style="margin-top: 16px">
      <div class="title">
        <div class="line"></div>
        <div class="text">资料附件</div>
      </div>
    </div> -->
    <el-dialog :close-on-click-modal="false" title="工伤认定申请表" width="65%" :visible.sync="saveDialogVisable" :append-to-body="true" @closed="closed">
      <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="165px" label-position="left">
        <el-row :gutter="28"> </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="申请人姓名" prop="applicant">
              <el-input v-model="saveData.applicant" placeholder="请输入申请人姓名" :maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="受伤害职工姓名" prop="injuredEmployees" label-width="125px">
              <el-input v-model="saveData.injuredEmployees" placeholder="请输入受伤害职工姓名" :maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="申请人与受伤职工关系" prop="applicantInjuredRelation">
              <el-input v-model="saveData.applicantInjuredRelation" placeholder="请输入申请人与受伤职工关系" :maxlength="10"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactNumber" label-width="125px">
              <el-input v-model="saveData.contactNumber" placeholder="请输入联系电话" :maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="申请人地址" prop="applicantAddr">
              <el-input v-model="saveData.applicantAddr" placeholder="请输入申请人地址" :maxlength="100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮政编码" prop="postalCode" label-width="125px">
              <el-input v-model="saveData.postalCode" placeholder="请输入邮政编码" :maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="申请日期" prop="completionDate">
              <el-date-picker v-model="saveData.completionDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <div class="file">
              <div class="label">工伤认定申请表:</div>
              <file-upload
                :file-type="[]"
                :files="applicationList"
                :limit="1"
                :size-limit="20"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
              <a :href="attachmentUrl[3].attachmentUrl" class="downTmp">下载模板</a>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="file">
              <div class="label" style="width: 115px">工伤证人证言:</div>
              <file-upload
                :file-type="[]"
                :files="witnessTestimonyList"
                :limit="1"
                :size-limit="20"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
              <a :href="attachmentUrl[4].attachmentUrl" class="downTmp">下载模板</a>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisable = false">取消</el-button>
        <el-button type="primary" @click="save()" >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validate from '@/assets/js/validate';
import fileUpload from '@/components/FileUpload';
import { mapState } from 'vuex';

import { QueryTable } from '@/assets/js/QueryTable';
export default {
  name: 'policyService',
  data() {
    let validatorTelephone = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('请输入手机号'));
        return;
      }
      if (validate.isValidPhone(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };
    return {
      applicationList: [],
      witnessTestimonyList: [],
      saveDialogVisable: false,
      saveData: {},
      status: [],
      saveInitData: {
        applicant: '',
        injuredEmployees: '',
        applicantInjuredRelation: '',
        contactNumber: '',
        applicantAddr: '',
        postalCode: '',
        completionDate: '',
        applicationList: [],
        witnessTestimonyList: [],
      },
      saveRules: {
        applicant: [{ required: true, message: '请输入申请人姓名', trigger: 'blur' }],
        injuredEmployees: [{ required: true, message: '请输入受伤害职工姓名', trigger: 'blur' }],
        applicantInjuredRelation: [{ required: true, message: '请输入申请人与受伤职工关系', trigger: 'blur' }],
        // contactNumber: [{ required: true, validator: validatorTelephone, trigger: 'blur' }],
        contactNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        applicantAddr: [{ required: true, message: '请输入申请人地址', trigger: 'blur' }],
        postalCode: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
        completionDate: [{ required: true, message: '请选择填表日期', trigger: 'blur' }],
      },
      queryTable: new QueryTable({}, {}, this.$api.findWorkInjuryDeclarePage),
    };
  },
  components: {
    fileUpload,
  },
  computed: {
    ...mapState({
      attachmentUrl: (state) => state.attachmentUrl,
    }),
  },
  methods: {
    closed() {
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
        this.applicationList = [];
        this.witnessTestimonyList = [];
      });
    },
    add() {
      this.saveDialogVisable = true;
    },
    edit(row) {
      this.saveDialogVisable = true;
      this.$api.getWorkInjuryDeclareById({ id: row.id }).then((res) => {
        if (res.success) {
          this.saveData = JSON.parse(JSON.stringify(res.result));
          this.applicationList = res.result.applicationList;
          this.witnessTestimonyList = res.result.witnessTestimonyList;
        }
      });
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          if (this.applicationList.length < 1) {
            this.$message.error({ message: '请上传工伤认定申请表', offset: 80 });
            return;
          }
          if (this.witnessTestimonyList.length < 1) {
            this.$message.error({ message: '请上传证人证言', offset: 80 });
            return;
          }
          this.saveData.applicationList = JSON.stringify(this.applicationList);
          this.saveData.witnessTestimonyList = JSON.stringify(this.witnessTestimonyList);

          this.$api.editWorkInjuryDeclare(this.saveData).then((res) => {
            if (res.success) {
              this.queryTable.search();
              if (this.saveData.id) {
                this.$message.success({ message: '修改成功', offset: 80 });
              } else {
                this.$message.success({ message: '新增成功', offset: 80 });
              }
            } else {
              this.$message.error({ message: res.message, offset: 80 });
            }
            this.saveDialogVisable = false;
          });
        } else {
          return false;
        }
      });
    },
    del(id) {
      this.$confirm(`确定删除此数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$api.deleteWorkInjuryDeclareById({ id: id }).then((res) => {
          if (res.success) {
            this.$message.success({ message: '删除成功', offset: 80 });
            this.queryTable.search();
          } else {
            this.$message.error({ message: res.message, offset: 80 });
          }
        });
      });
    },
    formatterStatus(row) {
      let status = this.status.filter((v) => {
        return row.declareStatus == v.itemValue;
      })[0];
      return status ? status.itemDesc : '';
    },
  },
  created() {
    this.queryTable.search();

    this.$api.getkey({ dictKey: 'declare_status' }).then((res) => {
      this.status = res.result;
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .table-box {
      margin-top: 20px;
    }
  }
}
.file {
  display: flex;
  line-height: 40px;
  .label {
    width: 155px;
    text-align: right;
  }
  .downTmp {
    text-decoration: none;
  }
}
</style>
